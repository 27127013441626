(function($){
    $.fn.loadPicture = function( callBack, verbose=false ){
        if ((callBack == undefined) || ((typeof callBack) == "function")) {
            this.each(function(){
                let $that = $(this);
                    $that.sources = $that.find('source');
                    $that.image = $that.find('img');
                    $that.callBack = callBack;
                    $that.loaded = $that.attr('data-loaded');
                    if($that.loaded != undefined){
                        $that.loaded = Number($that.loaded);
                    } else {
                        $that.loaded = 0;
                    }
                    $that.image.on('load',function(){
                        if(verbose){
                            console.log('Picture Loaded: ');
                            console.log($that);
                        }
                        if(callBack != undefined){
                            $that.callBack();
                            $that.attr('data-loaded',1);
                        }
                    });
                if($that.loaded != 1){
                    $that.sources.each(function(){
                        let $source = $(this);
                            $source.srcset = $source.attr('data-srcset');
                            if(verbose){
                                console.log("Source Detected");
                                console.log($source);
                            }
                        if($source.srcset == undefined){
                            console.error('This Source Tag Does not have a Required Attribute [data-srcset]');
                            console.log($source);
                        } else {
                            $source.attr('srcset',$source.srcset);
                            if(verbose){
                                console.log("Source Loaded");
                                console.log($source);
                            }
                        }
                    });
                    $that.image.realsrc = $that.image.attr('data-src');
                    if ($that.image.realsrc == undefined) {
                        console.error('Error on Image Loading:');
                        console.error($that.image);
                        console.error('Required Attribute [data-src] is Missing');
                    } else {
                        $that.image.webp = $that.image.attr('data-webp');
                        $that.image.jpg2000 = $that.image.attr('data-jpg2000');
                        $that.image.jpgxr = $that.image.attr('data-jpgxr');
                        if($that.image.jpgxr != undefined){
                            $that.image.attr('data-final',$that.image.jpgxr);
                            if(verbose){
                                console.log('data-final for $that.image images replaced with JPG XR Source');
                                console.log($that.image);
                            }
                        } else {
                            if(verbose){
                                console.warn('$that.image Image does not holds a JPG XR Image URL');
                                console.log($that.image);
                            }
                        }
                        if($that.image.jpg2000 != undefined){
                            $that.image.attr('data-final',$that.image.jpg2000);
                            if(verbose){
                                console.log('data-final for $that.image images replaced with JPG2000 Source');
                                console.log($that.image);
                            }
                        } else {
                            if(verbose){
                                console.warn('$that.image Image does not holds a JPG2000 Image URL');
                                console.log($that.image);
                            }
                        }
                        if($that.image.webp != undefined){
                            $that.image.attr('data-final',$that.image.webp);
                            if(verbose){
                                console.log('data-final for $that.image images replaced with WEBP Source');
                                console.log($that.image);
                            }
                        } else {
                            
                            if(verbose){
                                console.warn('$that.image Image does not holds a WEBP Image URL');
                                console.log($that.image);
                            }
                        }
                        $that.image.final = $that.image.attr('data-final');
                        if ($that.image.final == undefined){
                            if(verbose){
                                console.warn("No Compress Format For $that.image iamge Detected, Default to original format");
                                console.log($that.image);
                            }
                            $that.image.attr('data-final',$that.image.realsrc);
                            $that.image.final = $that.image.attr('data-final');
                        }
                        $that.image.attr('src',$that.image.final);
                    }
                } else {
                    console.warn('This Picture Was Already loaded');
                    console.warn($that);
                }
            });
        } else {
            console.error(`
                Syntax Error: 
                Parameter on Optional Parameter 1 $(element).loadPicture(function,boolean);
                The Parameter 1 You Entered is not a function. 

                Example: 
                
                $('.element').loadPicture(function(){
                    // Image Loaded Do Something
                });

                Example: Load Image and Get Loading Information

                $('.element').loadPicture(function(){
                    // Image Loaded Do Something
                },true);
            
            `);
        }
    }
}(jQuery));